import $ from 'jquery'

var videoElement = $('.videoYT')

videoElement.each(function (index, value) {
  const youtubeID = $(this).attr('data-youtubeid')
  const vimeoID = $(this).attr('data-vimeoid')

  // Play the video
  const btnPlayVideo = $(this).find('.playVideo, .thumbnail')
  const videowrapper = $(this).find('.featuredImageIngress__video')
  let mute

  btnPlayVideo.click(function () {
    videowrapper.addClass('active')
    if ($(window).width() < 992) { mute = 1 } else { mute = 0 } // On mobile, the video needs to be muted for autoplay to work

    if (youtubeID) {
      videowrapper.find($('.img'))
        .prepend("<iframe src='https://www.youtube.com/embed/" + youtubeID + '?autoplay=1&mute=' + mute + "' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen style='background: black;'></iframe>")
    } else if (vimeoID) {
      videowrapper.find($('.img'))
        .prepend(`<iframe src="https://player.vimeo.com/video/${vimeoID}?autoplay=1&muted=${mute}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style='background: black;'></iframe>`)
    }

  })

  // Close the video
  const closeVideo = $(this).find('.closeVideo')

  closeVideo.click(function () {
    videowrapper.removeClass('active')
    videowrapper.find('iframe').remove()
  })
})
