import $ from 'jquery'

$(document).ready(function () {
  if ($(".blogPageHeader").length) {
    //CATEGORY
    var btnSelect = $(".blogPageCategory__items .selectedItem"),
      options = $(".blogPageCategory__items--wrapper"),
      option = $(".blogPageCategory__items--wrapper .item"),
      items = $(".blogPageCategory__items")

    btnSelect.on("click", function () { //only for mobile
      items.toggleClass("active")
    })

    option.on("click", function (event) {
      event.preventDefault()
      btnSelect.find("span").html($(this).text())
      items.toggleClass("active")
      option.removeClass('active')
      $(this).addClass('active')

      const url = $(this).attr('href')

      window.location.href = url
    })
    //CATEGORY

    if ($('.blogListPosts').length) {
      const buttonShowMore = $('.blogListPosts__showMore')
      buttonShowMore.click(function (event) {
        event.preventDefault()
        appendBlogPosts()
        // appendBlogPosts()
      })
    }
  }

  if ($(".blogPageHeader").length) {
    $(".categoryFilter").on('click', function(e) {
      const paramCategory = $(this).attr('id')
      let location
      if (paramCategory !== 'all_categories') {
        location = `${document.location.origin}${document.location.pathname}?category=${paramCategory}`
        $('.blogListPosts__showMore').attr('data-category', paramCategory)
      } else {
        location = `${document.location.origin}${document.location.pathname}`
        $('.blogListPosts__showMore').attr('data-category', '')
      }
      history.pushState(
        undefined,
        undefined,
        location
      )
      appendBlogPosts(paramCategory, 1)
    })
  }
})

function appendBlogPosts (paramCategory, paramPage) {
  const searchFromCategoriesHeader = !!paramCategory
  const category = paramCategory || $('.blogListPosts__showMore').attr('data-category')
  let page = paramPage ? parseInt(paramPage) : parseInt($('.blogListPosts__showMore').attr('data-page')) + 1
  $('.blogListPosts__showMore').attr('data-page', page)
  page = paramPage ? parseInt(paramPage) : parseInt($('.blogListPosts__showMore').attr('data-page'))

  const sericeUrl = category && category !== 'all_categories' ? $('.blogListPosts__showMore').attr('data-service') + '?page=' + page + '&category=' + category : $('.blogListPosts__showMore').attr('data-service') + '?page=' + page
  // console.log(sericeUrl)

  $.ajax({
    url: sericeUrl,
    async: false,
    success: function (data) {
      //   console.log(data)
      // Add new elements
      const newBlock = data && data.page && data.result && buildBlock(data.result, searchFromCategoriesHeader)
      if (searchFromCategoriesHeader) {
        $('.blogListPosts .container.blogBlocks').empty()
        $('.blogListPosts .container.blogBlocks').append(newBlock)
      } else {
        $('.blogListPosts .container.blogBlocks').append(newBlock)
      }
      if (!data.showMore) {
        $('.blogListPosts__showMore')
          .parent()
          .parent()
          .parent()
          .fadeOut()
      } else {
        $('.blogListPosts__showMore')
          .parent()
          .parent()
          .parent()
          .fadeIn()
      }
    } // end success
  })
}

function buildBlock (block, searchFromCategoriesHeader) {
  let block1 = searchFromCategoriesHeader ? 1 : 0
  let block3 = 0
  let bigBlock = 'left'
  let bigBlockClass = bigBlock === 'left' ? 'bg-flower imgLeft' : 'mr-40 bg-cloud imgRight'
  let newHTMLBlock = ''
  let block3Html = ''
  let row = ''

  block.forEach((item, index) => {
    if (block1 === 0) {
      row = `<div class="row">\
        <div class="${bigBlock === 'right' ? 'offset-lg-1' : ''} col-lg-15">\
          <div class="blogListPosts__itemLandscaspeWrapper ${bigBlockClass}">\
            <a href="${(item.link && item.link.href) || ''}"\
            target="${(item.link && item.link.target) || ''}">\
              <div class="item">\
                <div class="imgWrapper">\
                  <div class="img aspectRatio--739x484">\
                    ${item.image ? `<img src='${item.image.link}' alt='${item.image.alt}' title='${item.image.title}'/>` : ''}\
                  </div>\
                </div>\
                <div class="info">\
                  <h3 class="title">${item && item.title}</h3>\
                  <p class="content">${item && item.ingress}</p>\
                </div>\
              </div>\
            </a>\
          </div>\
        </div>\
      </div>`
      newHTMLBlock += row
      row = ''
      bigBlockClass = bigBlock === 'left' ? 'bg-flower imgLeft' : 'mr-40 bg-cloud imgRight'
      bigBlock = bigBlock === 'left' ? 'right' : 'left'
      block1++
      block3 = 0
    } else {
      if (block3 < 3) {
        block3Html += `<div class="item">\
            <div class="content">\
                <a href="${(item.link && item.link.href) || ''}"\
                  target="${(item.link && item.link.target) || ''}">\
                    <div class="imgWrapper">\
                      <div class="img aspectRatio--377x256">
                        ${item.image ? `<img src='${item.image.link}' alt='${item.image.alt}' title='${item.image.title}'/>` : ''}\
                      </div>\
                    </div>\
                    <div class="info">\

                    <h3 class="title">${item && item.title}</h3>\
                    </div>\
                </a>\
            </div>\
        </div>`
        block3++
        row = `<div class="row">\
              <div class="offset-lg-1 col-lg-15">\
                  <div class="blogListPosts__itemsWrapper ml-40 odd">\
                  ${block3Html}
                  </div>\
              </div>\
          </div>`

        if (block3 === 3) {
          block1 = 0
          block3 = 0
          bigBlockClass = bigBlock === 'left' ? 'bg-flower imgLeft' : 'mr-40 bg-cloud imgRight'
          newHTMLBlock += row
          block3Html = ''
          row = ''
        }
      }
    }
  })

  if (row) {
    newHTMLBlock += row
  }

  return newHTMLBlock
}
