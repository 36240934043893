import $ from 'jquery'
import '../external/sticky-sidebar.js'
$(document).ready(function () {
  if (!$('header').hasClass('header--steps')) {
    var stickTopBar = new StickySidebar('header', { // eslint-disable-line
      resizeSensor: true,
      containerSelector: '.page',
      innerWrapperSelector: 'header'
    })
  }
  // Hide Header on on scroll down
  var didScroll
  var lastScrollTop = 0
  var delta = 5
  var navbarHeight = $('header').outerHeight()

  $(window).scroll(function (event) {
    didScroll = true
  })

  setInterval(function () {
    if (didScroll) {
      hasScrolled()
      didScroll = false
    }
  }, 250)

  // Add link arrow
  var linkArrow = $('.dropDownMenu__desktop--items .item a, .dropDownMenu__desktop--subItems .item a,.dropDownMenu__desktop--sub-subItems .item a , .dropDownMenu__mobile--items .item a')
  addLinkArrow(linkArrow)
  function addLinkArrow (el) {
    el.html(function () {
      var text = $(this).text().trim().split(' ')
      var last = text.pop()
      var lastWord = last.substring(0, (last.length - 1))
      var lastChar = last.split('')
      var char = lastChar.pop()
      return text.join(' ') + (last.length ? ' ' + lastWord + '<span>' + char + '</span>' : last)
    })
  }
  // Add link arrow

  // Mockup Menu

  var dropDownMenu = $('.dropDownMenu')
  var importantMenssage = $('.importantMessage')
  var btnClose = $('.dropDownMenu .btnClose')
  var body = $('body')

  $('.dropDownMenu .btnClose, .main__menu--desktop .item.dropDown, .main__menu--mobile .item.menu-hamburguer').click(function () {
    dropDownMenu.toggleClass('active')
    body.toggleClass('bodyBlock')
    btnClose.toggleClass('active')
    importantMenssage.toggleClass('toHide')
  })
  $('.item.item--more > a').click(function () {
    $('.dropDownMenu__desktop--items').removeClass('active')
    $('.btnBack').attr('hidden', false)
  })
  $('.subLevel > a').click(function () {
    if (document.body.clientWidth <= 992) {
      $('.dropDownMenu__desktop--subItems').removeClass('active')
    }
    $('.btnBack').attr('hidden', false)
  })

  $('.btnBack').click(function () {
    if ($('.dropDownMenu__desktop--sub-subItems').hasClass('active')) {
      var activeMenu = $('.dropDownMenu__desktop--sub-subItems').filter('.active')
      $('.dropDownMenu__desktop--subItems').filter(`[data-subitem="${activeMenu.data().subitem.toString().split('.')[0]}"]`).addClass('active')
      $('.dropDownMenu__desktop--sub-subItems').removeClass('active')
    } else if ($('.dropDownMenu__desktop--subItems').hasClass('active')) {
      $('.dropDownMenu__desktop--subItems').removeClass('active')
      $('.dropDownMenu__desktop--items').addClass('active')
    }
    if (!($('.dropDownMenu__desktop--sub-subItems').hasClass('active') || $('.dropDownMenu__desktop--subItems').hasClass('active'))) {
      $('.btnBack').attr('hidden', true)
    }
  })

  $('.btnClose').click(function () {
    if ($('.dropDownMenu__desktop--subItems').hasClass('active')) {
      $('.dropDownMenu__desktop--subItems').removeClass('active')
      $('.dropDownMenu__desktop--items').addClass('active')
    }
    if ($('.dropDownMenu__desktop--sub-subItems').hasClass('active')) {
      $('.dropDownMenu__desktop--sub-subItems').removeClass('active')
      $('.dropDownMenu__desktop--items').addClass('active')
    }
    $('.btnBack').attr('hidden', true)
  })

  var itemsDropDownMenu = $('.dropDownMenu__desktop--items .item a')
  var dropDownMenuSubItems = $('.dropDownMenu__desktop--subItems')
  var thisItem
  // Mockup Menu

  // CAMILA-START
  var subItems_dropDown_menu = $(' .dropDownMenu__desktop--subItems .item--more a')
  var dropDownMenu__sub__subItems = $('.dropDownMenu__desktop--sub-subItems')
  var subItems_menu = $(' .dropDownMenu__desktop--subItems a')
  var itemsDropDownMenu_dropdown = $('.dropDownMenu__desktop--items .item--more a')
  // CAMILA-END

  $(itemsDropDownMenu).click(function () {
    thisItem = $(this).attr('data-subItem')
    itemsDropDownMenu.removeClass('active')
    $(this).toggleClass('active')
    dropDownMenu__sub__subItems.removeClass('active')
    dropDownMenuSubItems.each(function (index, value) {
      var _thisItem = $(this).attr('data-subItem')
      if (_thisItem === thisItem) {
        dropDownMenuSubItems.removeClass('active')
        $(".dropDownMenu__desktop--subItems[data-subItem='" + _thisItem + "']").addClass('active')
      }
    })
  })

  // var itemsDropDownMenuMobile = $('.dropDownMenu__mobile--items .item a')
  // var subItemsDropDownMenuMobile = $('header .dropDownMenu__mobile--items .item.item--expandable>a')
  // $(itemsDropDownMenuMobile).click(function () {
  //   $(this).toggleClass('active')
  // })
  // subItemsDropDownMenuMobile.click(function () {
  //   $(this).next('.subItems').toggleClass('active')
  // })
  // CAMILA-START
  $(subItems_dropDown_menu).click(function () {
    thisItem = $(this).attr('data-subItem')
    $(this).toggleClass('active')
    dropDownMenu__sub__subItems.each(function (index, value) {
      var _thisItem = $(this).attr('data-subItem')
      if (_thisItem == thisItem) {
        dropDownMenu__sub__subItems.removeClass('active')
        $(".dropDownMenu__desktop--sub-subItems[data-subItem='" + _thisItem + "']").addClass('active')
      }
    })
  })
  if ($(window).width() > 993) {
    $(itemsDropDownMenu).hover(function () {
      thisItem = $(this).attr('data-subItem')
      itemsDropDownMenu.removeClass('active')
      subItems_menu.removeClass('active')
      dropDownMenuSubItems.removeClass('active')
      dropDownMenu__sub__subItems.removeClass('active')

      dropDownMenuSubItems.each(function (index, value) {
        var _thisItem = $(this).attr('data-subItem')
        if (_thisItem === thisItem) {
          $(".dropDownMenu__desktop--subItems[data-subItem='" + _thisItem + "']").addClass('active')
        }
      })
    })
    $(itemsDropDownMenu_dropdown).hover(function () {
      $(this).toggleClass('active')
    })

    $(subItems_menu).hover(function () {
      dropDownMenu__sub__subItems.removeClass('active')
      subItems_menu.removeClass('active')
    })
    $(subItems_dropDown_menu).hover(function () {
      thisItem = $(this).attr('data-subItem')
      $(this).addClass('active')
      dropDownMenu__sub__subItems.each(function (index, value) {
        var _thisItem = $(this).attr('data-subItem')
        if (_thisItem == thisItem) {
          $(".dropDownMenu__desktop--sub-subItems[data-subItem='" + _thisItem + "']").addClass('active')
        }
      })
    })
  }
  // CAMILA-END

  // Mockup Menu

  function hasScrolled () {
    var st = document.scrollingElement.scrollTop

    // Make sure they scroll more than delta
    if (Math.abs(lastScrollTop - st) <= delta) { return }

    // If they scrolled down and are past the navbar, add class .nav-up.
    // This is necessary so you never see what is "behind" the navbar.
    if (st > lastScrollTop && st > navbarHeight) {
      // Scroll Down

      $('.header > .inner-wrapper-sticky').addClass('nav-up').trigger('classAdded')
    } else {
      // Scroll Up
      if (st + $(window).height() < $(document).height()) {
        $('.header > .inner-wrapper-sticky').removeClass('nav-up').trigger('classRemoved')
      }
    }

    lastScrollTop = st
  }

  goToShoppingCart()
})

function goToShoppingCart () {
  const headerData = $('.header.data')
  const checkoutUrlSalesforce = headerData.data('page-checkout-salesforce')
  let checkoutUrl

  const shoppingCartHeader = $('.header .shoppingCart')
  shoppingCartHeader.on('click', null, function () {
    $(this).unbind('click')

    checkCartExist()
    const shoppingCartId = $.cookie('shoppingCartIdSalesforce') || $.cookie('shoppingCartId')

    checkoutUrl = checkoutUrlSalesforce
    if (shoppingCartId) {
      window.location.href = `${checkoutUrl}?cartid=${shoppingCartId}`
    } else {
      const createCartServiceUrl = headerData.data('create-cart-service')

      $.ajax({
        url: createCartServiceUrl,
        data: { shoppingCartId: shoppingCartId },
        success: function (data) {
          // console.log(data)
          // return
          const cartId = data && data._id
          // console.log(checkoutUrl)
          if (cartId) {
            if ($.cookie('shoppingCartIdSalesforce') === undefined) {
              $.cookie('shoppingCartIdSalesforce', cartId, { expires: 365, path: '/' })
            // your code for first visit
            }

            if (data && data.programCanBeAddedToCart) {
              if (checkoutUrl && cartId) {
                window.location.href = `${checkoutUrl}?cartid=${cartId}`
              }
            }
          } else {
            shoppingCartHeader.bind('click')
          }
        } // end success
      })
    }
  })
}

function checkCartExist () {
  const headerData = $('.header.data')
  const checkCartExistServiceUrl = headerData.data('check-cart-exist')
  const shoppingCartId = $.cookie('shoppingCartIdSalesforce') || $.cookie('shoppingCartId')
  let result
  $.ajax({
    url: checkCartExistServiceUrl,
    type: 'POST',
    async: false,
    data: { shoppingCartId: shoppingCartId },
    success: function (data) {
      // console.log(data)
      if (data && !(data.cartExist)) {
        $.removeCookie('shoppingCartIdSalesforce', { path: '/' })
      } else {
        result = data
      }
    } // end success
  })
  return result
}
