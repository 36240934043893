import $ from 'jquery'

$(document).ready(function () {

  // prevent the accordion from expanding when clicking on the link
  var linkProgram = $(".accordion--purple .card-header h2 .button a");
  linkProgram.click(function(event){ event.stopImmediatePropagation();});
  // prevent the accordion from expanding when clicking on the link

});
