import $ from 'jquery' //eslint-disable-line
import jQuery from 'jquery' //eslint-disable-line
import 'bootstrap/dist/js/bootstrap.min.js'
import 'popper.js/dist/popper.min.js'
import 'bootstrap-select/dist/js/bootstrap-select.min.js'
import gaDataLayer from './ga-data-layer'
import documentation from './documentation'
import '../../external/sticky-sidebar.js'
import fnrvalidator from '../../external/fnrvalidator'

var formDataAR = new FormData() //eslint-disable-line
const page = $('#checkoutCoursePageIDSalesforce')
const strings = page.data('strings')

$(document).ready(function () {
  if ($('#checkoutCoursePageIDSalesforce').length) {
    updateCookie()

    // Pop Up Regulation
    var popUpRegulation = $(".popUp-regulation-toggle"),
      popUpRegulationWrapper = $(".popUp-regulation-wrapper")
    popUpRegulation.on("click", function (event) {
      event.preventDefault()
      popUpRegulationWrapper.fadeToggle()
      $("body").toggleClass("overflowHidden")
    })
    // Pop Up Regulation

    activeToolTip()

    if (document.body.clientWidth > 720) {
      if ($('.header.header--steps') && $('.header.header--steps').length) {
        var sidebar = new StickySidebar('.header.header--steps', { // eslint-disable-line
          topSpacing: 0,
          leftSpacing: 0,
          minWidth: 0,
          bottomSpacing: 0,
          resizeSensor: true,
          containerSelector: '.page',
          innerWrapperSelector: '.header.header--steps'
        })
      }
    }

    // $$$$$$$$$$$$$$$$$$ CODE FROM FRONTEND TEAM - START $$$$$$$$$$$$$$$$$$
    // var dropDownCard = $('.dropDownCard1')
    // var selectedItem = dropDownCard.find('.selectedItem')
    // var dropDowntoggle = dropDownCard.find('.selectedItem, .arrowSelect')
    // var option = dropDownCard.find('.option')
    // var infoNAV = $('.infoNAV')
    // dropDowntoggle.click(function () {
    //   dropDownCard.toggleClass('active')
    // })
    // option.click(function () {
    //   dropDownCard.toggleClass('active')
    //   selectedItem.html($(this).text())
    //   selectedItem.attr('data-value', $(this).text())
    //   option.removeClass('active')
    //   $(this).addClass('active')
    //   var thisValue = $(this).text()
    //   if (thisValue === '- Valg for annen betaler -') {
    //     infoNAV.hide()
    //     $('#otherPaymentName').attr('style', 'display: none')
    //     $('#otherPaymentEmail').attr('style', 'display: none')
    //   } else if (thisValue === 'NAV') {
    //     infoNAV.show()
    //     $('#otherPaymentName').attr('placeholder', 'NAV kontor').attr('style', 'display: block')
    //     $('#otherPaymentEmail').attr('placeholder', 'Saksbehandler').attr('style', 'display: block')
    //   } else {
    //     infoNAV.hide()
    //     $('#otherPaymentName').attr('placeholder', 'Navn').attr('style', 'display: block')
    //     $('#otherPaymentEmail').attr('placeholder', 'E-postadresse').attr('style', 'display: block')
    //   }
    // })

    // var dropDownCard2 = $('.dropDownCard2')
    // var selectedItem2 = dropDownCard2.find('.selectedItem')
    // var dropDowntoggle2 = dropDownCard2.find('.selectedItem, .arrowSelect')
    // var option2 = dropDownCard2.find('.option')
    // dropDowntoggle2.click(function () {
    //   dropDownCard2.toggleClass('active')
    // })
    // option2.click(function () {
    //   dropDownCard2.toggleClass('active')
    //   selectedItem2.html($(this).text())
    //   selectedItem2.attr('data-value', $(this).text())
    //   option2.removeClass('active')
    //   $(this).addClass('active')
    // })

    // $('.basket__item__box .styled-radio input').on('click', null, function () { // checkbox check checkout frontpage
    //   const classes = $(this).parent().attr('class')
    //   const classId = classes.split(' ')[1]

    //   $(`.${classId} input`).removeClass('active')
    //   $(this).addClass('active')

    //   $(this).parent().parent().addClass('active')
    //   $(this).parent().parent().removeClass('inactive')
    //   // formData.append('subjects', JSON.stringify(subjectArray))
    // })

    // $$$$$$$$$$$$$$$$$$ CODE FROM FRONTEND TEAM - END $$$$$$$$$$$$$$$$$$

    // $$$$$$$$$$$$$$$$$$ DOCUMENTATION SECTION - START $$$$$$$$$$$$$$$$$$
    documentation.checkAdmissionRequeriment($)
    documentation.setDocumentationForm($)
    documentation.sendForm($, jQuery, checkCartExist, updateCookie, displaySalesforcePageError)
    documentation.populateArrayOfFiles($)
    // $$$$$$$$$$$$$$$$$$ DOCUMENTATION SECTION - END $$$$$$$$$$$$$$$$$$

    // $$$$$$$$$$$$$$$$$$ GO BACK BUTTON - START $$$$$$$$$$$$$$$$$$
    const backPage = $('.checkout__title .back-link a')
    backPage.click(function () {
      if ($(this).attr('href') === 'javascript:void(0)') {
        if ($('.step3').is(':visible')) { // Back from step3 to step2
          $('.step3').hide()
          $('.header-step3').removeClass('active')
          !$('.header-step3').hasClass('done') && $('.header-step3').addClass('disabled')

          if ($('.stepOptionalDocumentation').length) { // If stepOptionalDocumentation exists, back from step3 to it
            $('.stepOptionalDocumentation').show()
            $('.header-stepOptionalDocumentation').addClass('active')
            $('.header-stepOptionalDocumentation').removeClass('done')
          } else { // If stepOptionalDocumentation not exists, back from step3 to step2
            $('.step2').show()
            $('.header-step2').addClass('active')
            $('.header-step2').removeClass('done')
          }
        } else if ($('.stepOptionalDocumentation').is(':visible')) { // Back from stepOptionalDocumentation to step2
          $('.stepOptionalDocumentation').hide()
          $('.header-stepOptionalDocumentation').removeClass('active')
          !$('.header-stepOptionalDocumentation').hasClass('done') && $('.header-stepOptionalDocumentation').addClass('disabled')

          $('.step2').show()
          $('.header-step2').addClass('active')
          $('.header-step2').removeClass('done')
        } else if ($('.step2').is(':visible')) { // Back from step2 to step1
          $('.step2').hide()
          $('.header-step2').removeClass('active')
          !$('.header-step2').hasClass('done') && $('.header-step2').addClass('disabled')

          $('.step1').show()
          $('.header-step1').addClass('active')
          $('.header-step1').removeClass('done')
        } else {
          goBack()
        }
      }
    })
    // $$$$$$$$$$$$$$$$$$ GO BACK BUTTON - END $$$$$$$$$$$$$$$$$$

    // $$$$$$$$$$$$$$$$$$ STEP1 CTA - START $$$$$$$$$$$$$$$$$$
    const buttonStep1 = $('#btn-step1')

    if (buttonStep1.length) {
      const cartExist = page.data('cart-exist')
      if (cartExist) {
        const cartId = page.data('cartid-from-req')
        if ($.cookie('shoppingCartIdSalesforce') === undefined && cartId) {
          $.cookie('shoppingCartIdSalesforce', cartId, { expires: 365, path: '/' })
        }
      }
    }

    // $$$$$$$$$$$$$$$$$$ REDIRECT TO ADD MORE COURSES CTA - START $$$$$$$$$$$$$$$$$$
    const rediretcToCoursePageButton = $('.basket__item--add')
    rediretcToCoursePageButton.click(function () {
      window.location.href = page.data('url')
    })
    // $$$$$$$$$$$$$$$$$$ REDIRECT TO ADD MORE COURSES CTA - END $$$$$$$$$$$$$$$$$$

    const buttonStep1ToStep2 = $('#btn-step1')
    buttonStep1ToStep2.on('click', null, function () {
      updateCookie()

      const button = $(this)
      // const buttonText = $(this).text()
      // $(this).text('')
      // console.log(buttonText)
      setLoadButton($(this))

      checkCartExist()

      // console.log($('.basket__item__box.inactive'))
      if ($('.basket__item__box.inactive').length) {
        // console.log($('.basket__item__box.inactive'))
        goToScrollTop($('.basket__item__box.inactive'))
        removeLoadButton($(this))
        return
      }

      $('.step1').hide()
      $('.step2').show()

      $('.header-step1').removeClass('active')
      $('.header-step1').addClass('done')

      $('.header-step2').removeClass('disabled')
      $('.header-step2').addClass('active')

      goToScrollTop($('.header.header--steps'))
      removeLoadButton($(this))

      const shoppingCartId = page.data('cartid-from-req')

      gaDataLayer.processGADataLayerCheckoutSteps($, shoppingCartId, 2)
    })
    // $$$$$$$$$$$$$$$$$$ STEP1 CTA - END $$$$$$$$$$$$$$$$$$

    setDefaultArrowPointer()

    // $$$$$$$$$$$$$$$$$$ STEP2 CTA - SAVE USER INFORMATION ON DATABASE - START $$$$$$$$$$$$$$$$$$
    if ($('#btn-step2').length) {
      document.getElementById('btn-step2').addEventListener('click', createPersonalInformation, false)
    }
    // $$$$$$$$$$$$$$$$$$ STEP2 CTA - SAVE USER INFORMATION ON DATABASE - END $$$$$$$$$$$$$$$$$$



    // $$$$$$$$$$$$$$$$$$ HEADER BUTTONS TO CONTROL CHECKOUT FLOW - START $$$$$$$$$$$$$$$$$$
    const stepTabs = $('.header--steps .main__menu .item')

    stepTabs.on('click', null, function () {
      if (!$(this).hasClass('disabled')) {
        if (!$(this).hasClass('active')) {
          const idStepToHide = $('.header--steps .main__menu .item.active').attr('id')
          const stepToHide = idStepToHide && idStepToHide.split('-')[1]
          $(`#${idStepToHide}`).addClass('done')
          $(`#${idStepToHide}`).removeClass('active')

          $(this).removeClass('done')
          $(this).addClass('active')

          const idStepToShow = $(this).attr('id')
          const stepToShow = idStepToShow && idStepToShow.split('-')[1]

          $(`.${stepToHide}`).hide()
          $(`.${stepToShow}`).show()
        }
      }
    })
    // $$$$$$$$$$$$$$$$$$ HEADER BUTTONS TO CONTROL CHECKOUT FLOW - END $$$$$$$$$$$$$$$$$$

    const buttonStep3ToStep4 = $('#btn-step3')
    buttonStep3ToStep4.on('click', null, function () {
      updateCookie()
      const acceptTerms = $('#terms').hasClass('active')
      if (!acceptTerms) {
        $('#terms').addClass('invalid')
        $('html, body').animate(
          {
            scrollTop: $('#terms').parent().parent().offset().top - 200
          }, 700)
        return
      }
      $('#terms').removeClass('invalid')

      checkCartExist()


      medLearnPaymentMethod()

    })

    // remove courses from basket
    const buttonRemoveCourse = $('.checkout__section.basket__item .btn.btn-remove')
    buttonRemoveCourse.on('click', null, function () {
      checkCartExist()
      $('.btn.basket__item--add').removeClass('d-none')
      $('.checkout__bottom.basket__total').addClass('d-none')
      const removeItemCartUrl = page.data('remove-item-cart')
      const idProgramToRemove = $(this).attr('id')
      const isCourse = $(this).hasClass('remove-course')
      const shoppingCartId = page.data('cartid-from-req')
      const itemToRemove = $(this).parent()
      // console.log(removeItemCartUrl)
      // console.log(isCourse)

      gaDataLayer.processGADataLayerRemoveFromCart($, idProgramToRemove, shoppingCartId)
      $.ajax({
        url: removeItemCartUrl,
        data: jQuery.param({ shoppingCartId: shoppingCartId, idProgramToRemove: idProgramToRemove, isCourse: isCourse }),
        success: function (data) {
          // console.log(data)
          if (data && (data.programRemoved === true)) {
            itemToRemove.remove()
            $(`#item-step1-${idProgramToRemove}`).remove()
            $(`#item-step2-${idProgramToRemove}`).remove()
            $(`#item-step-optional-${idProgramToRemove}`).remove()
            $(`#item-step3-${idProgramToRemove}`).remove()
            $(`#item-step4-${idProgramToRemove}`).remove()

            if (!data.totalCartItems) {
              $('#btn-step1').hide()

              $('.header-step2').removeClass('active done')
              $('.header-step2').addClass('disabled')
              $('.header-stepOptionalDocumentation').removeClass('active done')
              $('.header-stepOptionalDocumentation').addClass('disabled')
              $('.header-step3').removeClass('active done')
              $('.header-step3').addClass('disabled')
              $('.header-step4').removeClass('active done')
              $('.header-step4').addClass('disabled')
            } else {
              $('#btn-step1').show()
            }
          }

        } // end success
      })
    })

    $('.newsletter').on('click', null, function () {
      $(this).toggleClass('active')
    })
    newsletterRegistration()

    checkBoxstudyRegulations()

    checkBoxConsent()

    radioInputCompetence()

    questionOnChange()

    countCharacteresTextArea()
  }
})

/**
 * character count for the text area fields of the checkout forms
 */
function countCharacteresTextArea () {
  document.getElementById('question').onkeyup = function () {
    const count = document.getElementById('countCharacterQuestion')
    count.innerHTML = `${(25000 - this.value.length)}/25000`
    if (this.value.length === 25000) {
      $('#countCharacterQuestion').css({ color: 'red' })
    } else {
      $('#countCharacterQuestion').css({ color: 'gray' })
    }
  }
}

/**
 * verifies that the key of the stored cookie is the same as the page request. If not, the cookie is updated so that it tracks the request key
 */
function updateCookie () {
  // if cartId from request != cookie cartId
  // then check if cart exists.
  // if cart exist then update the cookie
  const cartIdFromReq = page.data('cartid-from-req')
  const cartIdFromCookie = $.cookie('shoppingCartIdSalesforce')
  if (cartIdFromReq) {
    if (cartIdFromReq !== cartIdFromCookie) {
      const checkCartExistServiceUrl = page.data('check-cart-exist')
      $.ajax({
        url: checkCartExistServiceUrl,
        type: 'POST',
        async: false,
        data: jQuery.param({ shoppingCartId: cartIdFromReq }),
        success: function (data) {
          if (data && (data.cartExist)) {
            $.cookie('shoppingCartIdSalesforce', cartIdFromReq, { expires: 365, path: '/' })
          }
        } // end success
      })
    }
  }
}

/**
 * performs coupon operations as soon as the user changes the value typed in the field
 */
function questionOnChange () {
  $('#question').on('change', function () {
    const button = $('#btn-step3')
    const serviceUrl = page.data('service')
    const question = $('#question').val()
    const cartIdFromReq = page.data('cartid-from-req')

    // if (question.length) {
    setLoadButton(button)
    $.ajax({
      url: serviceUrl,
      type: 'POST',
      // async: false,
      data: { shoppingCartId: cartIdFromReq, question: question || ' ' },
      success: function (data) {
        if (data && (data.status === 'success')) {
          $('#questionInfo').remove()
          removeLoadButton(button)
        } else {
          if (!$('#questionInfo').length) {
            $('.checkout__section.formGroup.questionInfo').append(
              `<div class="row resize-col" id="questionInfo">
                <div class="col-lg-10">
                  <div class="box-orange">
                    <p>Et problem oppstod under kommunikasjon med serveren</p>
                  </div>
                </div>
              </div>`
            )
            removeLoadButton(button)
          }
        }
      } // end success
    })
  })
}

/**
 * tick the radio button for acceptance of terms at checkout
 */
function checkBoxstudyRegulations () {
  $('#terms').on('click', null, function () {
    $(this).toggleClass('active')
  })
}

/**
 * tick the radio button for consent
 */
 function checkBoxConsent () {
  $('#consent').on('click', null, function () {
    $(this).toggleClass('active')
  })
}


/**
 * tick the radio button for professional competence
 */
function radioInputCompetence () {
  $('.inputCompetence input').on('click', null, function () {
    $('.inputCompetence input').removeClass('active')
    $(this).addClass('active')
  })
}


/**
 * activates a button spinner
 * @param {*} element
 */
function setLoadButton (element) {
  element.prop('disabled', true)
  element.css('opacity', '1')
  element.addClass('btn--loading')
}

/**
 * disables the button spinner
 * @param {*} element
 */
function removeLoadButton (element) {
  element.removeClass('btn--loading')
  element.prop('disabled', false)
}

/**
 * make a request to the backend to subscribe the customer to the site's newsletter
 */
function newsletterRegistration () {
  const newsletterServiceUrl = page.data('newsletter-service')
  const cartIdFromReq = page.data('cartid-from-req')

  const footer = $('footer')
  const newsletterConfirmationPage = footer.data('newsletter-confirmation-page')

  // btn-step4

  const buttonNewsletter = $('#btn-step4')

  buttonNewsletter.on('click', null, function () {
    if (!termsWereChecked()) {
      return
    }
    const inputNewsletter = $('.newsletter.active')
    if (inputNewsletter.length) {
      $.ajax({
        url: newsletterServiceUrl,
        data: jQuery.param({ cartId: cartIdFromReq }),
        success: function (data) {
          if (data && (data.status === 'success')) {
            if (newsletterConfirmationPage) {
              window.location.href = newsletterConfirmationPage
            }

            buttonNewsletter.hide()
          } else {
            // console.log(data)
            // TODO:
          }
        } // end success
      })
    }
  })
}

/**
 * check if the shopping cart exists in the database
 */
function checkCartExist () {
  const checkCartExistServiceUrl = page.data('check-cart-exist')
  const shoppingCartId = page.data('cartid-from-req')
  let cart = {}
  $.ajax({
    url: checkCartExistServiceUrl,
    type: 'POST',
    async: false,
    data: jQuery.param({ shoppingCartId: shoppingCartId }),
    success: function (data) {
      cart = data && data.cart
      if (data && !(data.cartExist)) {
        window.scrollTo(0, 0)
        window.location.reload()
      }
    } // end success
  })

  return cart
}

/**
 * apply capitalization to a string
 * @param {String} str string that must be modified
 * @param {Boolean} lower apply lower case to string
 * @returns {String} modified string
 */
function capitalize (str, lower = false) {
  // console.log(str)
  return (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase())
}

/**
 * handles the payment.
 */
function medLearnPaymentMethod () {
  const button = $('#btn-step3')

  setLoadButton(button)
  // if ((name && email)) {
  const checkoutGeneralService = page.data('checkout-courses-general-service')
  const shoppingCartId = page.data('cartid-from-req')

  const studentRegisterSalesforceService = page.data('student-register-salesforce-service')

  $.ajax({
    url: studentRegisterSalesforceService,
    // async: async,
    data: { shoppingCartId: shoppingCartId, action: 'contact, opportunity'},
    success: function (data) {
      if (data) {
        // const result = data


        // const customerId = result && result.customerId
        // const orderId = result && result.orderId
        // // const orderId = true
        // if (!customerId || !orderId) {
        //   removeLoadButton(button)
        //   displaySalesforcePageError(button)
        // } else {
        //   $.ajax({
        //     url: checkoutGeneralService,

        //     data: { shoppingCartId: shoppingCartId, action: 'finishCart' },
        //     success: function (data) {
        //       if ((data.cart && data.cart.data && data.cart.data.status === 'checkout_complete')) {
        //         gaDataLayer.processGADataLayerPurchase($, shoppingCartId, 'other')

        //         goToConfirmationPage()
        //         goToScrollTop($('.header.header--steps'))
        //         $.removeCookie('shoppingCartIdSalesforce', { path: '/' })
        //         removeLoadButton(button)
        //       }
        //     }
        //   })
        // }
        $.ajax({
          url: checkoutGeneralService,
          data: { shoppingCartId: shoppingCartId, action: 'finishCart' },
          success: function (data) {
            if ((data.cart && data.cart.data && data.cart.data.status === 'checkout_complete')) {
              gaDataLayer.processGADataLayerPurchase($, shoppingCartId, 'other')

              goToConfirmationPage()
              goToScrollTop($('.header.header--steps'))
              $.removeCookie('shoppingCartIdSalesforce', { path: '/' })
              removeLoadButton(button)
            }
          }
        })
      } else {
        removeLoadButton(button)
      }
    }
  })

}



/**
 * if the payment operation is successful then the user is redirected to the payment screen.
 */
function goToConfirmationPage () {
  const cartIdFromReq = page.data('cartid-from-req')
  if (cartIdFromReq) {
    $('.step1').hide()
    $('.step2').hide()
    $('.step3').hide()
    $('.step4').show()

    $('.header-step1').removeClass('active done')
    $('.header-step1').addClass('disabled')
    $('.header-step2').removeClass('active done')
    $('.header-step2').addClass('disabled')
    $('.header-stepOptionalDocumentation').removeClass('active done')
    $('.header-stepOptionalDocumentation').addClass('disabled')
    $('.header-step3').removeClass('active done')
    $('.header-step3').addClass('disabled')
    $('.header-step4').removeClass('disabled')
    $('.header-step4').addClass('active')

    const customerInfo = page.data('get-cart-customer-info')

    if (customerInfo) {
      $.ajax({
        url: customerInfo,
        type: 'POST',
        data: { cartIdFromReq: cartIdFromReq },
        success: function (data) {
          if (data) {
            // console.log(data)
            // append to section: Takk for din bestilling
            $('.checkout__text-info.withTitle.col-lg-8').append(`<ul>
            <li>
              Du vil motta ordrebekreftelse i løpet av kort tid. I denne finner du informasjon om når du kan forvente svar på søknaden. Du vil bli kontaktet om vi trenger mer dokumentasjon fra deg.
            </li>
          </ul>
          <h6>Er det noe du lurer på? Kontakt oss gjerne:</h6>

          <p>man-fre: 09-16</p>
          <p>67 58 88 00</p>`)

            // append to section: Student
            $('.checkout__section.student.mb-128.customerInformation').append(`<div class="row resize-col">

            <div class="form-group form-readonly col-md-8 col-lg-4">
              <label for="inputName">Fornavn</label>
              ${data.inputName ? `<div>${data.inputName}</div>` : ''}
            </div>


            <div class="form-group form-readonly col-md-8 col-lg-4">
              <label for="inputName">Etternavn</label>
              ${data.inputLastName ? `<div>${data.inputLastName}</div>` : ''}
            </div>



          </div>
          <div class="row resize-col">

            <div class="form-group form-readonly col-md-8 col-lg-4">
            <label for="inputPhoneNumber">Telefonnummer</label>
              ${data.inputPhoneNumber ? `<div>${data.inputCountryCode || ''}${data.inputPhoneNumber}</div>` : ''}
            </div>

            <div class="form-group form-readonly col-md-8 col-lg-4">
            <label for="inputEmail">E-post </label>
            ${data.inputEmail ? `<div>${data.inputEmail}</div>` : ''}
            </div>

          </div>
          <div class="row resize-col">
            <div class="form-group form-readonly col-md-8 col-lg-4">
              <label for="inputSocialNumber">Personnummer</label>
              ${data.inputSocialNumber ? `<div>${data.inputSocialNumber}</div>` : ''}
            </div>
          </div>`)
          }
        } // end success
      })
    }
  }
}

/**
 * scrolls page to selected element
 * @param {*} element
 * @param {Number} h height the page should scroll to the element.
 */
function goToScrollTop (element, h = 20) {
  if (element.length) {
    $('html, body').animate(
      {
        scrollTop: element.offset().top - h
      }, 700)
  }
}

/**
 * adds CSS code for correct cursor display
 */
function setDefaultArrowPointer () {
  var css = document.createElement('style')
  css.type = 'text/css'
  css.innerHTML = '.header.header--steps .item.disabled {cursor: default!important;}'
  document.body.appendChild(css)
}

/**
 * executes request to persist customer information in shopping cart
 * @param {Boolean} setHeaders variable to handle the behavior of the checkout header
 */
function createPersonalInformation (setHeaders = true) {
  updateCookie()
  const button = $('#btn-step2')

  var serviceUrl = page.data('service')
  var price = page.data('price')
  var formData = new FormData($('#formPersonalInformation')[0]) //eslint-disable-line

  const shoppingCartId = page.data('cartid-from-req')

  // formData.append('programCourse', programCourse)
  formData.append('price', price)
  formData.append('shoppingCartId', shoppingCartId)
  if ($('.inputCompetence input.active').length) {
    formData.set('inputCompetence', $('.inputCompetence input.active').attr('value'))
  }

  const consent = $('#consent')
  if (consent.hasClass('active')) {
    formData.set('consent', true)
  } else {
    formData.set('consent', false)
  }

  // var check = formData.get('inputName')
  // console.log(check)

  const hasRequiredFieldEmpty = checkRequiredFields(formData)

  if (hasRequiredFieldEmpty) {
    return
  } else {
    // $('#btn-step2').hide()
  }

  setLoadButton(button)

  $.ajax({
    url: serviceUrl,
    type: 'POST',
    data: formData,
    processData: false,
    contentType: false,
    success: function (data) {
      // console.log(data)
      if (data && data.status && data.status === 'success') {
          if (setHeaders) {
            documentation.setHeaderOptions($)
          }

          removeChildrenFromElement('div', '.removeIfUpdate')

          if (data.dataNode && data.dataNode.inputCountryCode && data.dataNode.inputPhoneNumber) {
            $('#inputPhoneNumber-step3').append(`<div class='removeIfUpdate'>${data.dataNode.inputCountryCode}${data.dataNode.inputPhoneNumber}</div>`)
          }

          $('#inputName-step3').append(data.dataNode && data.dataNode.inputName && data.dataNode.inputLastName ? `<div class='removeIfUpdate'>${data.dataNode.inputName}</div>` : '')
          $('#inputSurname-step3').append(data.dataNode && data.dataNode.inputName && data.dataNode.inputLastName ? `<div class='removeIfUpdate'>${data.dataNode.inputLastName}</div>` : '')
          $('#inputSocialNumber-step3').append(data.dataNode && data.dataNode.inputSocialNumber ? `<div class='removeIfUpdate'>${data.dataNode.inputSocialNumber}</div>` : '')
          $('#inputEmail-step3').append(data.dataNode && data.dataNode.inputEmail ? `<div class='removeIfUpdate'>${data.dataNode.inputEmail}</div>` : '')
          // $('#inputAddress-step3').append(data.dataNode && data.dataNode.inputAddress ? `<div class='removeIfUpdate'>${data.dataNode.inputAddress}</div>` : '')
          // $('#inputCity-step3').append(data.dataNode && data.dataNode.inputZipCode && data.dataNode.inputCity ? `<div class='removeIfUpdate'>${data.dataNode.inputZipCode} ${data.dataNode.inputCity}</div>` : '')
          // $('#inputCountry-step3').append(data.dataNode && data.dataNode.inputCountry && data.dataNode.inputCountry.name ? `<div class='removeIfUpdate'>${capitalize(data.dataNode.inputCountry.name, true)}</div>` : '')


          goToScrollTop($('.header.header--steps'))

          gaDataLayer.processGADataLayerCheckoutSteps($, shoppingCartId, 3)
        removeLoadButton(button)
      } else {
        // console.log(data)
        if (data && !data.cartExist) {
          window.scrollTo(0, 0)
          window.location.reload()
        }
        removeLoadButton(button)
      }
    }
  })
}

/**
 * displays salesforce checkout error screen
 * @param {*} elementToScroll element the page should scroll to if the user clicks retry.
 */
function displaySalesforcePageError (elementToScroll) {
  const cartIdFromReq = page.data('cartid-from-req')

  $('#checkoutError .fail__section .btn__try-again').on('click', null, function () {
    // callback()
    $('#checkoutError').hide()
    $('#checkoutCoursePageIDSalesforce').show()
    $('.header__wrapper .main__menu').show()
    goToScrollTop(elementToScroll)
  })

  if (cartIdFromReq) {
    // if (cartIdFromReq !== cartIdFromCookie) {
    // console.log(cartIdFromReq)
    const checkCartExistServiceUrl = page.data('check-cart-exist')
    $.ajax({
      url: checkCartExistServiceUrl,
      type: 'POST',
      async: false,
      data: jQuery.param({ shoppingCartId: cartIdFromReq }),
      success: function (data) {
        if (data && (data.cartExist)) {
          // console.log(data.cart)
          const cart = data.cart

          if (cart && cart.data && cart.data.customerInfo) {
            $('#checkoutError .contact__section .user-info .user-info-inputName').text(`${cart.data.customerInfo.inputName} ${cart.data.customerInfo.inputLastName}`)
            $('#checkoutError .contact__section .user-info .user-info-inputEmail').text(cart.data.customerInfo.inputEmail)
            $('#checkoutError .contact__section .user-info .user-info-inputPhoneNumber').text(cart.data.customerInfo.inputCountryCode || '' + cart.data.customerInfo.inputPhoneNumber)
            $('#checkoutError .contact__section .user-info .user-info-inputAddress').text(cart.data.customerInfo.inputAddress)
            $('#checkoutError .contact__section .user-info .user-info-inputCity').text(cart.data.customerInfo.inputCity)
            $('#checkoutError .contact__section .user-info .user-info-inputCountry').text(cart.data.customerInfo.inputCountry.name)

            $('#checkoutError').show()
            $('#checkoutCoursePageIDSalesforce').hide()
            $('.header__wrapper .main__menu').hide()
            goToScrollTop($('#checkoutError'))
          }
        }
      } // end success
    })
    // }
  }
}

/**
 * removes children from a given element
 * @param {*} tag parent tag
 * @param {*} tagClass child class that should be removed
 */
function removeChildrenFromElement (tag, tagClass) {
  $(tag).remove(tagClass)
}

/**
 * simulates the browser's back button
 */
function goBack () {
  if (window.history.length >= 2) {
    // if history is not empty, go back:
    window.history.back()
  }
  // else {
  //   // go home:
  //   window.history.replaceState(null, null, '/')
  //   window.location.replace('/')
  // }
}

/**
 * check if all form fields are filled in correctly
 * @param {*} formData data filled in the form
 * @returns {Boolean} true if there is an error
 */
function checkRequiredFields (formData) {
  const inputName = formData.get('inputName')
  const inputLastName = formData.get('inputLastName')
  const inputSocialNumber = formData.get('inputSocialNumber')
  const inputEmail = formData.get('inputEmail')
  const inputPhoneNumber = formData.get('inputPhoneNumber')
  const inputZipCode = formData.get('inputZipCode')
  const inputCity = formData.get('inputCity')
  const inputCountry = formData.get('inputCountry')
  const inputCountryCode = formData.get('inputCountryCode')
  const inputAddress = formData.get('inputAddress')
  const inputCompetence = formData.get('inputCompetence')

  let backToTop = false

  if (inputName === '') {
    const inputNameElement = $('#inputName')
    inputNameElement.attr('placeholder', 'Påkrevd')
    inputNameElement.addClass('invalid')
    backToTop = true
  } else {
    const inputNameElement = $('#inputName')
    inputNameElement.removeClass('invalid')
  }

  if (inputLastName === '') {
    const inputLastNameElement = $('#inputLastName')
    inputLastNameElement.attr('placeholder', 'Påkrevd')
    inputLastNameElement.addClass('invalid')
    backToTop = true
  } else {
    const inputLastNameElement = $('#inputLastName')
    inputLastNameElement.removeClass('invalid')
  }

  if (inputCountryCode === '') {
    const inputCountryCodeElement = $('#inputCountryCode')
    inputCountryCodeElement.attr('placeholder', 'Påkrevd')
    inputCountryCodeElement.addClass('invalid')
    invalidFieldsToBeScrolledTo.push(inputCountryCodeElement)
    backToTop = true
  } else {
    const inputCountryCodeElement = $('#inputCountryCode')
    inputCountryCodeElement.removeClass('invalid')
  }

  if (inputSocialNumber === '') {
    const inputSocialNumberElement = $('#inputSocialNumber')
    inputSocialNumberElement.attr('placeholder', 'Påkrevd')
    inputSocialNumberElement.addClass('invalid')
    backToTop = true
  } else {
    const inputSocialNumberElement = $('#inputSocialNumber')

    const validator = fnrvalidator.fnr(inputSocialNumber)

    if (inputSocialNumber === '6073910828') {
      validator.status = 'valid'
    }

    if ((validator && validator.status && validator.status === 'invalid')) {
      inputSocialNumberElement.addClass('invalidField')
      backToTop = true
    } else {
      inputSocialNumberElement.removeClass('invalid')
      inputSocialNumberElement.removeClass('invalidField')
    }
  }

  if (inputEmail === '') {
    const inputEmailElement = $('.form-group.form-required input.inputEmail')
    inputEmailElement.attr('placeholder', 'Påkrevd')
    inputEmailElement.addClass('invalid')
    backToTop = true
  } else {
    const validEmail = validateEmail(inputEmail)
    if (!validEmail) {
      const inputEmailElement = $('.form-group.form-required input.inputEmail')
      if (inputEmailElement.hasClass('invalid')) {
        inputEmailElement.removeClass('invalid')
      }
      inputEmailElement.attr('placeholder', 'Påkrevd')
      inputEmailElement.addClass('invalidField')
      backToTop = true
    } else {
      const inputEmailElement = $('.form-group.form-required input.inputEmail')
      if (inputEmailElement.hasClass('invalidField')) {
        inputEmailElement.removeClass('invalidField')
        // inputEmailElement.addClass('invalid')
        // console.log('has invalidField')
      }
    }
  }

  if (inputPhoneNumber === '') {
    const inputPhoneNumberElement = $('#inputPhoneNumber')
    inputPhoneNumberElement.attr('placeholder', 'Påkrevd')
    inputPhoneNumberElement.addClass('invalid')
    backToTop = true
  } else {
    const inputPhoneNumberElement = $('#inputPhoneNumber')
    inputPhoneNumberElement.removeClass('invalid')
  }

  if (inputZipCode === '') {
    const inputZipCodeElement = $('#inputZipCode')
    inputZipCodeElement.attr('placeholder', 'Påkrevd')
    inputZipCodeElement.addClass('invalid')
    backToTop = true
  } else {
    const inputZipCodeElement = $('#inputZipCode')
    inputZipCodeElement.removeClass('invalid')
  }

  if (inputCity === '') {
    const inputCityElement = $('#inputCity')
    inputCityElement.attr('placeholder', 'Påkrevd')
    inputCityElement.addClass('invalid')
    backToTop = true
  } else {
    const inputCityElement = $('#inputCity')
    inputCityElement.removeClass('invalid')
  }

  if (!inputCountry) {
    const inputCountryElement = $('#inputCountry')
    inputCountryElement.addClass('invalidFieldSelect')
    backToTop = true
  } else {
    const inputCountryElement = $('#inputCountry')
    inputCountryElement.removeClass('invalidFieldSelect')
  }

  if (inputAddress === '') {
    const inputAddressElement = $('#inputAddress')
    inputAddressElement.attr('placeholder', 'Påkrevd')
    inputAddressElement.addClass('invalid')
    backToTop = true
  } else {
    const inputAddressElement = $('#inputAddress')
    inputAddressElement.removeClass('invalid')
  }

  if (!inputCompetence) {
    // const inputCompetenceElement = $('.inputCompetence input')
    // inputCompetenceElement.addClass('invalid')
    $('.inputCompetence label').css({ color: 'red' })
    backToTop = true
  } else {
    // const inputCompetenceElement = $('.inputCompetence input')
    // inputCompetenceElement.removeClass('invalid')
    $('.inputCompetence label').css({ color: '#000000' })
  }

  if (backToTop) {
    goToScrollTop($('.checkout__section form'))
  }

  return backToTop
}

/**
 * check if the format of an email is valid
 * @param {String} email
 * @returns {Boolean}
 */
function validateEmail (email) {
  const re = /^[a-zA-Z0-9]+(?:[._-][a-zA-Z0-9]+)*@[a-zA-Z0-9]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/ //eslint-disable-line
  return re.test(String(email).toLowerCase())
}

function activeToolTip () {
  var toolTipItem = $('.helpTooltip')
  var thisToolTip
  $.each(toolTipItem, function (index, value) {
    thisToolTip = $(this).attr('toolTip')
    $(this).removeAttr('toolTip')
    $(this).attr('id', 'toolTip-' + index)
    tippy('#toolTip-' + index, { // eslint-disable-line
      content: thisToolTip + "<span class='closeTooltip'></span",
      trigger: 'click',
      allowHTML: true,
      interactive: true,
      appendTo: document.body,
      theme: 'MED',
      offset: [-30, 25],
      placement: 'bottom-start',
      onShown (instance) {
        $('.closeTooltip').click(function () {
          instance.hide()
        })
      }
    })
  })
}

function termsWereChecked () {
  const checkbox = $('.checkout__bottom__content input[type=checkbox]:visible')
  if (!checkbox.is(':checked')) {
    checkbox.parent().addClass('invalid')
    return false
  }
  checkbox.parent().removeClass('invalid')
  return true
}
