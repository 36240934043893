import $ from 'jquery'

$(document).ready(function () {
  // Add link arrow
  var footerlinkArrow = $('footer .footerTop__col div a,footer .footerTop__col .link')
  addLinkArrow(footerlinkArrow)
  function addLinkArrow (el) {
    el.html(function () {
      var text = $(this).text().trim().split(' ')
      var last = text.pop()
      var lastWord = last.substring(0, (last.length - 1))
      var lastChar = last.split('')
      var char = lastChar.pop()
      return text.join(' ') + (last.length ? ' ' + lastWord + '<span>' + char + '</span>' : last)
    })
  }
  // Add link arrow

  newsletterRegistrationFooter()
})

function newsletterRegistrationFooter () {
  //
  const footer = $('footer')
  const newsletterServiceUrl = footer.data('newsletter-service')
  const newsletterConfirmationPage = footer.data('newsletter-confirmation-page')
  $('.hideMobile .footerTop__col .email, .showMobile .footerTop__col .email').submit(function (e) {
    e.preventDefault()
  })

  const buttonNewsletter = $('.hideMobile .footerTop__col .email .button, .showMobile .footerTop__col .email .button')

  buttonNewsletter.on('click', null, function () {
    const email = $('#emailHideMobile').val() || $('#emailShowMobile').val()
    termsWereChecked()

    if (validateEmail(email)) {
      $(this).parent().removeClass('error')

      if (!termsWereChecked()) {
        return
      }

      $.ajax({
        url: newsletterServiceUrl,
        data: { email: email },
        success: function (data) {
          if (data && (data.status === 'success')) {
            // console.log(data)
            $('#emailHideMobile').val('')
            $('#emailShowMobile').val('')

            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({ event: 'newsletter_registration' })
            if (newsletterConfirmationPage) {
              window.location.href = newsletterConfirmationPage
            }
          } else {
            // console.log(data)
            // TODO:
          }
        } // end success
      })
    } else {
      $(this).parent().addClass('error')
    }
  })
}

function validateEmail (email) {
  const re = /^[a-zA-Z0-9]+(?:[._-][a-zA-Z0-9]+)*@[a-zA-Z0-9]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/ //eslint-disable-line
  return re.test(String(email).toLowerCase())
}

function termsWereChecked() {
  const checkbox = $('.terms input[type=checkbox]:visible')
  if (!checkbox.is(':checked')) {
    checkbox.parent().addClass('invalid')
    return false
  }
  checkbox.parent().removeClass('invalid')
  return true
}
