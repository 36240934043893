import $ from 'jquery' //eslint-disable-line

$(document).ready(function () {
  const checkoutCompletedPage = $('#courseCannotBeAddedPageSalesforce')
  if (checkoutCompletedPage.length) {
    // manipulate navigation bar
    $('#header-step1').remove()
    $('#header-step2').remove()
    $('#header-stepOptionalDocumentation').remove()
    $('#header-step3').remove()
    $('#header-step4').remove()
    // manipulate navigation bar

    checkRemoveCookie(checkoutCompletedPage)

    const backPage = $('.checkout__title .back-link a')
    backPage.click(function () {
      goBack()
    })

    goToShoppingCart()
  }
})

/**
 * check if the cookie should be removed if the shopping cart no longer exists
 * @param {*} checkoutCompletedPage element that stores variables used at checkout
 */
function checkRemoveCookie (checkoutCompletedPage) {
  const removeCartIdFromCookie = checkoutCompletedPage.data('node-cart-not-exist')
  if (removeCartIdFromCookie) {
    $.removeCookie('shoppingCartIdSalesforce', { path: '/' })
    // console.log(removeCartIdFromCookie)
  }
}

/**
 * simulates the browser's back button
 */
function goBack () {
  if (window.history.length >= 2) {
    // if history is not empty, go back:
    window.history.back()
  }
}

/**
 * redirects user to shopping cart page
 */
function goToShoppingCart () {
  const checkoutCompletedPage = $('#courseCannotBeAddedPageSalesforce')
  $('.box-gray p a').on('click', null, function () {
    const shoppingCartId = $.cookie('shoppingCartIdSalesforce')
    const checkoutUrl = checkoutCompletedPage.data('checkout-url')
    window.location.href = `${checkoutUrl}?cartid=${shoppingCartId}`
  })
}
