import $ from 'jquery'

$(document).ready(function () {
  var selectCourse = $(".courseSelect")
  if (selectCourse.length) {
    $.each(selectCourse, function (index, value) {
      var selectCourseBtn = $(this).find(".courseSelect__btn button")
      var selectCourseOptions = $(this).find(".courseSelect__options")
      $(window).on("click", function () {
        selectCourseOptions.slideUp(300)
        selectCourseBtn.removeClass("active")
      })
      selectCourseBtn.on("click", function (event) {
        event.stopPropagation()
        selectCourseBtn.toggleClass("active")
        selectCourseOptions.slideToggle(300)
      })
      selectCourseOptions.on("click", function (event) {
        event.stopPropagation()
      })
    })
  }
})
